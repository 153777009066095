.Results {
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-top: 40px;
}

.result {
  width: 600px;
  margin-top: 20px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  padding: 20px 15px 10px 20px;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.h1 {
  margin: 0;
  font-size: 16pt;
}

.description {
  margin: 0;
  font-size: 12pt;
}
.category {
  margin: 0;
  font-weight: 600;
  font-size: 12pt;
}

.footer {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tags {
  display: flex;
  flex-direction: row;
}

.tag {
  margin-right: 10px;
}

.accuracy {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accuracyText {
  margin-right: 10px;
  font-weight: 600;
}

.App {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.logo {
  max-width: 250px;
  margin-top: 100px;
  margin-bottom: 30px;
}

.imprintLink {
  font-size: 7pt;
  margin-top: 10px;
}
